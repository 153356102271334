import axios from 'axios'
// eslint-disable-next-line
// const DLP = require('@google-cloud/dlp')
// import { DLP } from '@google-cloud/dlp'

var querystring = require('querystring')
import { API_LINK, B_AUTH_USER, B_AUTH_PASS, DEJANGO_URL, ANALYZE_URL } from '../constants'

export async function getAllSurvey() {
  var user = localStorage.getItem('djangouser')
  var client = localStorage.getItem('djangoclient')
  let url = API_LINK + '/getAllSurvey'
  return await axios({
    method: 'get',
    url,
    params: {
      user: user,
      client: client,
    },
    auth: {
      username: B_AUTH_USER,
      password: B_AUTH_PASS,
    },
  })
    .then(response => response.data)
    .then(_data => {
      return _data.data
    })
}

export async function createNewSurvey(input, file) {
  var user = localStorage.getItem('djangouser')
  var client = localStorage.getItem('djangoclient')
  const formData = new FormData()
  formData.append('sarve_name', input.sarve_name)
  formData.append('lang_id', input.lang_id)
  formData.append('user', user)
  formData.append('client', client)
  formData.append('button_color', input.button_color)
  formData.append('survey_logo', file)
  formData.append('pii_mask', input.pii_mask)
  formData.append('welcome_url', input.welcome_url || '')
  formData.append('instructions', input.instructions || '')
  // console.log("createNewSurvey", input.welcome_url)
  let url = API_LINK + '/createNewSurvey';

  return await axios
    .post(url, formData, {
      auth: {
        username: B_AUTH_USER,
        password: B_AUTH_PASS,
      },
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then(
      response => {
        return response.data
      },
      error => {
        console.log(error)
      },
    )
}
export async function getAllQuestionOfSurvey(survey_id) {
  let url = API_LINK + '/getAllQuestionOfSurvey/' + survey_id
  return await axios({
    method: 'get',
    url,
    auth: {
      username: B_AUTH_USER,
      password: B_AUTH_PASS,
    },
  })
    .then(response => response.data)
    .then(_data => {
      console.log(_data,"daaaaaaaaaaaaaaszdsdff");
      return _data.data
    })
}
export async function getAllAnswersOfQuestion(question_id) {
  let url = API_LINK + '/getAllAnswersOfQuestion/' + question_id
  return await axios({
    method: 'get',
    url,
    auth: {
      username: B_AUTH_USER,
      password: B_AUTH_PASS,
    },
  })
    .then(response => response.data)
    .then(_data => {
      return _data.data
    })
}
export async function deleteSurvey(survey_id) {
  let url = API_LINK + '/deleteSurvey/' + survey_id
  return await axios
    .post(
      url,
      {},
      {
        auth: {
          username: B_AUTH_USER,
          password: B_AUTH_PASS,
        },
      },
    )
    .then(
      response => {
        return response
      },
      error => {
        console.log(error)
      },
    )
}
export async function adddNewQuestion(survey_id, input, file) {
  console.log(JSON.stringify(input.options), input.options)
  const formData = new FormData()
  formData.append('question', input.question)
  formData.append('question_type', input.mcqQuestion)
  formData.append('question_image', file)
  formData.append('options', JSON.stringify(input.options))
  let url = API_LINK + '/adddNewQuestion/' + survey_id
  return await axios
    .post(url, formData, {
      auth: {
        username: B_AUTH_USER,
        password: B_AUTH_PASS,
      },
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then(
      response => {
        return response
      },
      error => {
        console.log(error)
      },
    )
}

export async function inviteRespondent(surveyid, input) {
  let url = API_LINK + '/inviteRespondent/' + surveyid
  return await axios
    .post(url, querystring.stringify(input), {
      auth: {
        username: B_AUTH_USER,
        password: B_AUTH_PASS,
      },
    })
    .then(
      response => {
        return response
      },
      error => {
        console.log(error)
      },
    )
}
export async function deleteQuestion(question_id) {
  let url = API_LINK + '/deleteQuestion'
  return await axios
    .post(url, querystring.stringify({ question_id: question_id }), {
      auth: {
        username: B_AUTH_USER,
        password: B_AUTH_PASS,
      },
    })
    .then(
      response => {
        return response
      },
      error => {
        console.log(error)
      },
    )
}

export async function getSurveyStats() {
  var user = localStorage.getItem('djangouser')
  var client = localStorage.getItem('djangoclient')
  let url = API_LINK + '/getSurveyStats'
  return await axios({
    method: 'get',
    url,
    params: {
      user: user,
      client: client,
    },
    auth: {
      username: B_AUTH_USER,
      password: B_AUTH_PASS,
    },
  })
    .then(response => response.data)
    .then(_data => {
      return _data.data
    })
}
export async function updateSurvey(surveyid, input, file) {
  let url = API_LINK + '/updateSurvey/' + surveyid
  const formData = new FormData()
  formData.append('title', input.title)
  formData.append('button_color', input.button_color)
  formData.append('survey_logo', file)
  formData.append('pii_mask', input.pii_mask)
  formData.append('welcome_url', input.welcome_url || '')
  formData.append('instructions', input.instructions || '')

  return await axios
    .post(url, formData, {
      auth: {
        username: B_AUTH_USER,
        password: B_AUTH_PASS,
      },
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then(
      response => {
        return response
      },
      error => {
        console.log(error)
      },
    )
}

export async function getSurveyDetail(survey_id) {
  let url = API_LINK + '/getSurveyDetail/' + survey_id
  return await axios({
    method: 'get',
    url,
    auth: {
      username: B_AUTH_USER,
      password: B_AUTH_PASS,
    },
  })
    .then(response => response.data)
    .then(_data => {
      return _data.data
    })
}
export async function updateQuestion(questionid, input, file) {
  const formData = new FormData()
  formData.append('question', input.question)
  formData.append('question_type', input.mcqQuestion)
  formData.append('options', JSON.stringify(input.options))
  formData.append('question_image', file)
  let url = API_LINK + '/updateQuestion/' + questionid
  return await axios
    .post(url, formData, {
      auth: {
        username: B_AUTH_USER,
        password: B_AUTH_PASS,
      },
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then(
      response => {
        return response
      },
      error => {
        console.log(error)
      },
    )
}
export async function getQuestioinDetail(question_id) {
  let url = API_LINK + '/getQuestioinDetail/' + question_id
  return await axios({
    method: 'get',
    url,
    auth: {
      username: B_AUTH_USER,
      password: B_AUTH_PASS,
    },
  })
    .then(response => response.data)
    .then(_data => {
      return _data.data
    })
}
export async function deleteAnswer(answerid) {
  let url = API_LINK + '/deleteAnswer/' + answerid
  return await axios
    .post(
      url,
      {},
      {
        auth: {
          username: B_AUTH_USER,
          password: B_AUTH_PASS,
        },
      },
    )
    .then(
      response => {
        return response
      },
      error => {
        console.log(error)
      },
    )
}

export async function checkAuth() {
  window.location.href = DEJANGO_URL + 'checkAuth'
}

export async function getProjectsApi() {
  var username = localStorage.getItem('username')
  let url = DEJANGO_URL + 'getAllCategories?username=' + username

  return await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {},
  })
    .then(response => response.json())
    .then(data => {
      const categoriesData =JSON.parse(data);
      localStorage.setItem('myCategores',JSON.stringify(categoriesData.user_categories))
      return JSON.stringify(categoriesData.user_categories)
    })
}

export async function activateDeactivateSurvey(survey_id, status) {
  let url = API_LINK + '/activateDeactivateSurvey/' + survey_id + '/' + status
  return await axios
    .post(
      url,
      {},
      {
        auth: {
          username: B_AUTH_USER,
          password: B_AUTH_PASS,
        },
      },
    )
    .then(
      response => {
        return response
      },
      error => {
        console.log(error)
      },
    )
}

export async function exportSurveys() {
  var user = localStorage.getItem('djangouser')
  var client = localStorage.getItem('djangoclient')
  let url = API_LINK + '/exportSurveys'
  return await axios({
    method: 'get',
    url,
    params: {
      user: user,
      client: client,
    },
    auth: {
      username: B_AUTH_USER,
      password: B_AUTH_PASS,
    },
  })
    .then(response => response.data)
    .then(_data => {
      return _data.data
    })
}

export async function getAllLanguages() {
  var user = localStorage.getItem('djangouser')
  var client = localStorage.getItem('djangoclient')
  let url = API_LINK + '/getAllLanguages'
  return await axios({
    method: 'get',
    url,
    params: {
      user: user,
      client: client,
    },
    auth: {
      username: B_AUTH_USER,
      password: B_AUTH_PASS,
    },
  })
    .then(response => response.data)
    .then(_data => {
      return _data.data
    })
}

export async function updateSurveyLanguage(parmas) {
  let url = API_LINK + '/updateSurveyLanguage'
  return await axios
    .post(url, querystring.stringify(parmas), {
      auth: {
        username: B_AUTH_USER,
        password: B_AUTH_PASS,
      },
    })
    .then(
      response => {
        return response
      },
      error => {
        console.log(error)
      },
    )
}
export function getImageDummyObj() {
  return {
    name: 'xxx.png',
    status: 'done',
    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  }
}
