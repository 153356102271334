import React from 'react'
// import { FormattedMessage } from 'react-intl'

class Status extends React.Component {
  render() {
    return (
      <div>
        {/* <FormattedMessage id="topBar.status" /> */}
        {/* <span className="ml-2 p-2 badge bg-success text-white font-size-12 text-uppercase">
          Upgrade
        </span> */}
      </div>
    )
  }
}

export default Status
