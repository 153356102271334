export const API_LINK = 'https://surveyhost.commerce.ai/api'
export const API_AJAX = 'https://surveyhost.commerce.ai/ajax'
// export const API_LINK = 'http://localhost:3000/api'
// export const API_AJAX = 'http://localhost:3000/ajax'
export const B_AUTH_USER = 'sarve'
export const B_AUTH_PASS = 'snippop!@#'
// export const DEJANGO_URL = 'http://104.40.24.95:8000/'
// export const DEJANGO_URL = 'https://aiprep.commerce.ai/'
export const DEJANGO_URL = 'https://ai.commerce.ai/'
// export const DEJANGO_URL = 'http://127.0.0.1:8000/'
// export const ANALYZE_URL = 'http://104.40.24.95:8001/'
// export const ANALYZE_URL = 'http://ai2prepinternal.commerce.ai/'
export const ANALYZE_URL = 'https://ai2internal.commerce.ai/'
// export const ANALYZE_URL = "http://127.0.0.1:8001/"
