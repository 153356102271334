import React from 'react'
import ReactDOM from 'react-dom'
import { createHashHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
// import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import { checkAuth, getProjectsApi } from 'services/api'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './localization'
import Router from './router'
import * as serviceWorker from './serviceWorker'
// app styles

import './global.scss' // app & third-party component styles
// middlewared
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }

// Checking Django Auth
const checkA = async () => {
  return await checkAuth()
}
const getProjects = () => {
  return getProjectsApi()
}
if (true) {
  const pageURL = window.location.href
  let lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1)
  lastURLSegment = lastURLSegment.split('?')
  const parms = lastURLSegment[1]
  const userid = new URLSearchParams(parms).get('u')
  const name = new URLSearchParams(parms).get('n')
  const clientid = new URLSearchParams(parms).get('c')
  const username = new URLSearchParams(parms).get('un')
  const email = new URLSearchParams(parms).get('email')
  let trial = new URLSearchParams(parms).get('t')
  const payment_type = new URLSearchParams(parms).get('pt')
  const surveys_limit = new URLSearchParams(parms).get('sl')
  const profile_picture = new URLSearchParams(parms).get('pp')
  const subscription_status = new URLSearchParams(parms).get('st')
  const color = new URLSearchParams(parms).get('color')
  const theme = new URLSearchParams(parms).get('theme')
  const logo = new URLSearchParams(parms).get('cl')
  if (lastURLSegment[0] !== 'auth') {
    checkA()
  } else {
    if (trial < 1) {
      trial = 0
      // window.location.href = DEJANGO_URL+'integrations/dashboard'
    }
    localStorage.setItem('djangouser', userid)
    localStorage.setItem('djangoclient', clientid)
    localStorage.setItem('djangousername', name)
    localStorage.setItem('username', username)
    localStorage.setItem('email', email)
    localStorage.setItem('trial', trial)
    localStorage.setItem('payment_type', payment_type)
    localStorage.setItem('subscription_status', subscription_status)
    localStorage.setItem('surveys_limit', parseInt(surveys_limit))
    localStorage.setItem('profile_picture', profile_picture)
    localStorage.setItem('kit.primary', color)
    localStorage.setItem('theme', theme)
    localStorage.setItem('logo', logo)

    getProjects()
    history.push('/surveys')
  }
}

const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }
