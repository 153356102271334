import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Footer extends React.Component {
  render() {
    const {
      settings: { isContentNoMaxWidth },
    } = this.props
    return (
      <div
        className={classNames(style.footer, {
          [style.footerFullWidth]: isContentNoMaxWidth,
        })}
      >
        <div className={style.inner}>
          <div className="row">
            <div className="col-md-8">
              <p>
                <strong>Commerce.AI - Voice Survey</strong>
              </p>
              <p>
              Throw away your old and boring survey forms! Commerce.AI Voice Survey is a new way to conduct surveys. A tool for your team to get engaging, natural and rich responses in seconds. Switch tabs to access AI analysis for the responses.
              </p>
              <p>&copy; {(new Date().getFullYear())} Commerce.AI</p>
            </div>
            <div className="col-md-4">
              <div className={style.logo}>
                <img src="resources/images/commerceai_logo.svg" alt="Commerce.AI" />
                <div className={style.logoName}>Commerce.AI</div>
                <div className={style.logoDescr}>Voice Surveys</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
