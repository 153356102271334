/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Modal } from 'antd'
import styles from './style.module.scss'
import { getSurveyStats } from '../../../services/api'

const { confirm } = Modal
// import { DEJANGO_URL } from '../../../constants'

class SubBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalSurvey: 0,
      totalResponses: 0,
    }
  }

  componentDidMount = async () => {
    const data = await getSurveyStats();
    console.log("data",data)
    this.setState({ totalSurvey: data.totalSurvey, totalResponses: data.totalAnswers })
    localStorage.setItem('total_surveys', data.totalSurvey)
  }

  checkSurveyAllowed = () => {
    const sl = localStorage.getItem('surveys_limit')
    const ts = localStorage.getItem('total_surveys')
    // console.log('totalSurvey=>', ts)
    // console.log('surveyLimit=>', sl)

    // if (ts >= sl) {
    //   confirm({
    //     content: 'Survey creation limit exceeded. Do you want to increase limit?',
    //     async onOk() {
    //       window.location.href = DEJANGO_URL + 'integrations/dashboard'
    //     },
    //     onCancel() {
    //       console.log('Cancel')
    //     },
    //   })
    // } else {
    window.location = '#/survey/create'
    // }
  }

  render() {
    const { totalSurvey, totalResponses } = this.state
    console.log(this.state);
    return (
      <div className={styles.subbar}>
        <ul className={`${styles.breadcrumbs} mr-4`}>
          {/* <li className={styles.breadcrumb}>
            <a href="#" className={styles.breadcrumbLink}>
              Main
            </a>
          </li> */}
          <li className={styles.breadcrumb}>
            <a href="#/surveys" className={`${styles.breadcrumbLink} color-gray-4 `}>
              Dashboard
            </a>
          </li>
        </ul>
        <div className={`${styles.divider} mr-4 d-none d-xl-block`} />
        {/* <p className="color-gray-4 text-uppercase font-size-18 mb-0 mr-4 d-none d-xl-block">
          INV-00125
        </p> */}
        <a
          type="button"
          // href="#/survey/create"
          onClick={this.checkSurveyAllowed}
          className="btn btn-primary btn-with-addon mr-auto text-nowrap d-none d-md-block"
        >
          <span className="btn-addon">
            <i className="btn-addon-icon fe fe-plus-circle" />
          </span>
          New Survey
        </a>
        <div className={`${styles.amount} mr-3 ml-auto d-none d-sm-flex`}>
          <p className={styles.amountText}>
            Surveys
            <span className={styles.amountValue}>{totalSurvey}</span>
          </p>
          <div className={styles.amountGraph}>
            <i className={styles.amountGraphItem} style={{ height: '100%' }} />
          </div>
        </div>
        <div className={`${styles.amount} d-none d-sm-flex`}>
          <p className={styles.amountText}>
            Responses
            <span className={styles.amountValue}>{totalResponses}</span>
          </p>
          <div className={styles.amountGraph}>
            <i className={styles.amountGraphItem} style={{ height: '100%' }} />
          </div>
        </div>
      </div>
    )
  }
}

export default SubBar
