import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Menu, Dropdown, Avatar, Badge, Modal,Input,Form,message } from 'antd'
import styles from './style.module.scss'
import { DEJANGO_URL} from  '../../../../constants'

@connect(({ user }) => ({ user }))
class ProfileMenu extends React.Component {
  state = {
    count: 7,
    profile_picture:localStorage.getItem('profile_picture')
  }

  logout = e => {
    e.preventDefault()
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  addCount = () => {
    let { count } = this.state
    count += 1
    this.setState({
      count,
    })
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async e => { 
    this.setState({
      visible: false,
    });
    message.success("Profile updated successfully")
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };
  render() {
    const { user } = this.props
    const { count,profile_picture } = this.state
    
    const menu = (
      <Menu selectable={false}>
        {/* <Menu.Item>
          <strong>
            <FormattedMessage id="topBar.profileMenu.hello" />, {user.name || 'Anonymous'}
          </strong>
          <div>
            <strong className="mr-1">
              <FormattedMessage id="topBar.profileMenu.billingPlan" />:{' '}
            </strong>
            Professional
          </div>
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.role" />:{' '}
            </strong>
            {user.role}
          </div>
        </Menu.Item> */}
        {/* <Menu.Divider />
        <Menu.Item>
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.email" />:{' '}
            </strong>
            {user.email}
            <br />
            <strong>
              <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
            </strong>
            {user.phone || '— '}
          </div>
        </Menu.Item>
        <Menu.Divider /> */}
        {/* <Menu.Item>
          <a  onClick={() => this.showModal()}>
            <i className={`${styles.menuIcon} fe fe-user`} />
            <FormattedMessage id="topBar.profileMenu.editProfile" />
          </a>
        </Menu.Item> */}
        <Menu.Item>
          <a  href={`${DEJANGO_URL}integrations/profile`} >
            <i className={`${styles.menuIcon} fe fe-settings`} />
            Setting
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href={`${DEJANGO_URL}logout`}>
            <i className={`${styles.menuIcon} fe fe-log-out`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <>
        <Dropdown overlay={menu} trigger={['click']} onVisibleChange={this.addCount}>
          <div className={styles.dropdown}>
            <Badge >
              {/* <Avatar className={styles.avatar} shape="square" size="large" icon="user" /> */}
              <Avatar className={styles.avatar} shape="square" size="large" src={profile_picture} />
            </Badge>
            {/* <Badge count={count}>
              <Avatar className={styles.avatar} shape="square" size="large" icon="user" />
            </Badge> */}
          </div>
        </Dropdown>
        <Modal
            title="Update profile"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item  label="First Name">              
                <Input
                  placeholder="First Name here"
                /> 
              </Form.Item>
              <Form.Item  label="Last Name">              
                <Input
                  placeholder="Last Name here"
                />
                </Form.Item>
              <Form.Item  label="Email">              
                <Input
                  placeholder="Email"
                />
              </Form.Item>
          </Form>
         </Modal>
      </>
    )
  }
}

export default ProfileMenu
