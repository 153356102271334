import React from 'react'
// import Search from './Search'
import IssuesHistory from './IssuesHistory'
import Status from './Status'
import LanguageSwitcher from './LanguageSwitcher'
// import Actions from './Actions'
import UserMenu from './UserMenu'
import style from './style.module.scss'
import { DEJANGO_URL } from '../../../constants'
class TopBar extends React.Component {
  state = {
    trial: "Trial Expired",
  }

  componentDidMount = async () => {
    let t = localStorage.getItem('trial');
    const pt = localStorage.getItem('payment_type');
    const st = localStorage.getItem('subscription_status');

    if (pt == 'Postpaid' && st == 'Inactive') {
      if (parseInt(t) > 0) {
        t = "Trial Expiring in " + t + " days";
        this.setState({
          trial: t,
        })
      }
    } else {
      this.setState({
        trial: '',
      })
    }
  }
  render() {
    const { trial } = this.state
    return (
      <div className={style.topbar}>
        <div className="mr-md-4 mr-auto">
          <IssuesHistory />
        </div>
        <div className="mr-auto d-none d-md-block ml-5">
          {/* <a className="btn btn-with-addon mr-auto text-nowrap mr-4 d-none d-md-block pl-3" href={`${DEJANGO_URL}integrations/dashboard`} >
          <span className="btn-addon pt-2">
              <i className={`fe fe-plus-square`}></i>
          </span>
           New Project
          </a> */}
        </div>
        {/* <div className="d-none d-md-block">
        <a href="">
          <i className={`fe fe-plus-square`} /> Integrations
        </a>
        </div> */}

        <div className="mb-0 mr-4 d-xl-block d-none">
          <Status />
        </div>
        {/* <div className="mr-4 d-none d-sm-block">
          <LanguageSwitcher />
        </div> */}
        {trial &&
          <div className="mr-4 d-none d-sm-block">
            <a href={`${DEJANGO_URL}integrations/dashboard`} rel="noopener noreferrer" className="btn btn-sm btn-warning btn-rounded px-3">{trial}</a>
          </div>
        }
        <div className="">
          <UserMenu />
        </div>
      </div>
    )
  }
}

export default TopBar
