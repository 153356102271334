import React from 'react'
// import { FormattedMessage } from 'react-intl'
import { Menu, Dropdown } from 'antd'
import { Link } from 'react-router-dom'
import styles from './style.module.scss'
import { DEJANGO_URL,ANALYZE_URL} from  '../../../../constants'

class IssuesHistory extends React.Component {
  render() {
    let categores = JSON.parse(localStorage.getItem('myCategores'));
    console.log("categores",categores)
    const analyzeData= (c) =>{
      var username =localStorage.getItem('username');
      //window.location.href = ANALYZE_URL+"accounts/auth_analyze?check="+username+"&cat="+c;
    }
    //onClick={()=>analyzeData(label.category)}
    const menu = (
      <Menu selectable={false}>
        {categores && categores.length >0 && categores.map((label, index) => (
              <Menu.Item index={index +'rowKey'}>
                <a href={`${ANALYZE_URL}brand/analyze`} >
                 {label.category_displayname}
                </a>
              </Menu.Item>
            ))}
        <Menu.Divider /> 
        <Menu.Item>
          <a href={`${DEJANGO_URL}integrations/dashboard`}>
            <i className={`${styles.menuIcon} fe fe-plus-square`} /> Add Data
          </a>
        </Menu.Item>
      </Menu>
    )
    console.log(menu);
    return (
    <>
      <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
        <div >
        <a href=""  className="btn btn-with-addon mr-auto text-nowrap mr-4 d-none d-md-block pl-3" data-toggle="dropdown" aria-expanded="false" data-offset="0,15" >
          <span className="btn-addon">
              <i className="btn-addon-icon fa fa-chevron-down"></i>
          </span>
          Projects
        </a>
        </div>
      </Dropdown>
    </>
    )
  }
}

export default IssuesHistory
